input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.hot-toast {
  &-bar-base {
    --hot-toast-width: 430px;
    --hot-toast-close-btn-background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjY0OTEgMC4zNTA5MTlDMTEuMTgxIC0wLjExNjk3MyAxMC40MjI0IC0wLjExNjk3MyA5Ljk1NDMzIDAuMzUwOTE5TDYuMDA3NTEgNC4yOTc3NEwyLjA2MDY4IDAuMzUwOTE5QzEuNTkwNCAtMC4xMDMyOTggMC44NDI4NTQgLTAuMDk2ODAyIDAuMzgwNTMzIDAuMzY1NTE5Qy0wLjA4MTc4ODMgMC44Mjc4NCAtMC4wODgyODQyIDEuNTc1MzggMC4zNjU5MzMgMi4wNDU2N0w0LjMxMjc2IDUuOTkyNDlMMC4zNjU5MzMgOS45MzkzMkMwLjA1NDM2MTkgMTAuMjQwMiAtMC4wNzA1OTQxIDEwLjY4NTkgMC4wMzkwOTE3IDExLjEwNDlDMC4xNDg3NzggMTEuNTI0IDAuNDc2MDM2IDExLjg1MTIgMC44OTUwODQgMTEuOTYwOUMxLjMxNDEzIDEyLjA3MDYgMS43NTk3NiAxMS45NDU2IDIuMDYwNjggMTEuNjM0MUw2LjAwNzUxIDcuNjg3MjRMOS45NTQzMyAxMS42MzQxQzEwLjQyNDYgMTIuMDg4MyAxMS4xNzIyIDEyLjA4MTggMTEuNjM0NSAxMS42MTk1QzEyLjA5NjggMTEuMTU3MSAxMi4xMDMzIDEwLjQwOTYgMTEuNjQ5MSA5LjkzOTMyTDcuNzAyMjYgNS45OTI0OUwxMS42NDkxIDIuMDQ1NjdDMTIuMTE3IDEuNTc3NjQgMTIuMTE3IDAuODE4OTUzIDExLjY0OTEgMC4zNTA5MTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
    --hot-toast-close-btn-background-size: 13px;
    --hot-toast-close-btn-opacity: 1;
    --hot-toast-close-btn-margin-top: 0;
    --hot-toast-border-radius: 8px;
    --hot-toast-padding: 15px;

    &.hot-toast-bg {
      &-success {
        --hot-toast-bg: var(--ex-color-success);
      }

      &-warning {
        --hot-toast-bg: var(--ex-color-warning);
      }

      &-error {
        --hot-toast-bg: var(--ex-color-danger);
      }

      &-primary {
        --hot-toast-bg: var(--ex-color-primary-light);
      }
    }
  }

  &-animated-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    margin-right: 15px;
  }

  &-message {
    --hot-toast-message-margin: 0;

    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.mat-icon {
  width: var(--mat-icon-size) !important;
  height: var(--mat-icon-size) !important;
  font-size: var(--mat-icon-size);
  overflow: hidden;
  line-height: normal !important;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  --mat-icon-size: 24px;
}

input.mat-input-element {
  color: var(--ex-color-step-950);
  caret-color: var(--ex-color-primary);
}

// mat form fields
.mat-form-field {
  font-size: 15px;

  &.small {
    .mat-form-field-infix {
      width: 100px;
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      color: var(--ex-color-primary);
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: #f44336;
    }
  }
}

.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    background: var(--ex-bg-body);
  }

  .mat-form-field-underline {
    &::before {
      background: var(--ex-theme-border-color);
    }
  }
}

.mat-select-arrow {
  color: var(--ex-color-step-600);
}

.mat-select-value {
  color: var(--ex-color-step-950);
}

.mat-form-field-flex {
  height: 50px;
}

.mat-form-field-label-wrapper {
  top: -1em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.35em) scale(0.75);
}

.mat-form-field-label {
  color: var(--ex-color-step-500);
}

.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 0.5em 0;
  }

  &.mat-focused .mat-form-field-outline-thick {
    color: var(--ex-color-primary);
  }

  .mat-form-field-outline {
    color: var(--ex-color-step-300);

    &:hover {
      color: var(--ex-bg-block);
    }
  }

  .mat-form-field-label {
    top: 1.6em;
  }
}

.mat-form-field-prefix {
  margin-right: 10px;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  color: var(--ex-color-step-500);
}

// mat checkbox
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: var(--ex-color-primary);
}

.mat-checkbox-frame {
  border-color: var(--ex-color-step-300);
}

.mat-checkbox-layout {
  white-space: unset !important;

  .mat-checkbox-label {
    color: var(--ex-color-step-950);
    font-size: 13px;
  }
}

// mat tabs
.mat-tab-body-wrapper {
  height: 100%;
}

.mat-tab-label {
  flex: 1;
  opacity: 1 !important;
}

.mat-tab-label,
.mat-tab-link {
  color: var(--ex-color-step-950);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  color: var(--ex-color-primary);
}

// datepicker
.mat-datepicker-content {
  background: var(--ex-bg-block);
  color: var(--ex-color-step-950);
  border: 1px solid var(--ex-theme-border-color);
}

.mat-datepicker-toggle {
  svg {
    margin: auto;
    color: var(--ex-color-step-600);
  }
}

.mat-hint {
  color: var(--ex-color-step-450);
}

.mat-calendar-table-header {
  color: var(--ex-color-step-700);
}

.mat-calendar-body-label {
  color: var(--ex-color-step-400);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: var(--ex-color-step-900);
}

.mat-calendar-body-selected {
  background: var(--ex-color-primary);
  color: var(--ex-bg-block);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--ex-color-step-500);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--ex-color-step-450);
}

// dialogs
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.mat-dialog-container {
  padding: 0 !important;
  background: none;
  color: currentColor;
}

// mat buttons
.mat-icon-button {
  --mat-icon-size: 24px;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
  color: var(--ex-color-step-950);

  &[disabled] {
    opacity: 0.6;
  }
}

// mat menu
.mat-menu-panel {
  background: var(--ex-bg-block);
  border: 1px solid var(--ex-theme-border-color);
  min-height: auto !important;

  &.short {
    max-width: 500px;
  }

  &.max-w-min {
    max-width: min-content !important;
  }

  &.content-not-padding {
    .mat-menu-content {
      padding: 0;
    }
  }
}

.mat-menu-item {
  color: var(--ex-color-step-1000);

  &.active {
    background: var(--ex-color-step-100);
  }
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: var(--ex-color-step-550);
}

// custom
.disable-ripple:hover {
  background: transparent !important;
}

// mat list
.mat-list-base .mat-list-item {
  color: var(--ex-color-step-950);

  &:hover {
    background: var(--ex-color-step-50);
  }
}

.mat-divider {
  border-top-color: var(--ex-theme-border-color);
}

.mat-slide-toggle.mat-primary.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: var(--ex-color-primary);
  }

  .mat-slide-toggle-bar {
    background-color: var(--ex-color-primary-dark);
  }
}

.c-rootSidebarDropdown__menu {
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 15px;
    width: 0;
    height: 0;
    transform: translateY(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--ex-theme-border-color);
  }

  .logout {
    border-top: 1px solid var(--ex-theme-border-color);
  }

  language-selection {
    .mat-icon {
      margin-right: 0 !important;
    }
  }
}

.timepicker {
  &-overlay,
  &-backdrop-overlay {
    z-index: 9999 !important;
  }
}


//text editor
.ql-editor {
  color: var(--ex-color-step-950);

  &.ql-blank {
    &::before {
      color: var(--ex-color-step-500);
    }
  }
}

.ql-toolbar {
  &.ql-snow {
    border-color: var(--ex-theme-border-color);
  }
}

.ql-container {
  &.ql-snow {
    border-color: var(--ex-theme-border-color);
  }
}

.ql-snow {
  .ql-picker {
    color: var(--ex-color-step-700);

    &-label {
      color: var(--ex-color-step-700);
    }
  }

  .ql-stroke {
    stroke: var(--ex-color-step-700);
  }

  .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: var(--ex-color-step-700);
  }
}

//radio

.mat-radio {
  &-group {
    display: flex;
    margin-bottom: 20px;
  }

  &-outer-circle {
    border-color: var(--ex-color-step-800);
  }

  &-button {
    margin-right: 10px;

    &.mat-primary {
      &.mat-radio-checked {
        .mat-radio-outer-circle {
          border-color: var(--ex-color-primary);
        }
      }

      .mat-radio-inner-circle {
        background-color: var(--ex-color-primary);
      }
    }
  }
}

.mat-tooltip {
  font-size: 12px;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.6);
}
